<template>
  <div class="container">

    <div class="switch-container_outer">
      <div class="switch-container_inner" v-cloak>
        <transition @enter="enterSwitch">
          <div v-if="!trigger" class="switch-off"></div>
          <div v-else class="switch-on"></div>
        </transition>
      </div>
    </div>

    <transition @enter="textEnter">
      <div class="text" v-if="trigger">
        {{ skill }}
      </div>
      <div class="text invisible" v-else>
        {{ skill }}
      </div>

    </transition>


  </div>
</template>

<script>
export default {
  name: "mySkillsComp",
  updated() {

    setTimeout(() => {
      if (this.outer) {
        this.trigger = true
      }
    }, this.series * 1000 + 1500)
  },
  methods: {
    textEnter(el, doneFn) {
      el.animate([
        {opacity: '0', transform: 'translateX(2rem)'},
        {opacity: '1', transform: 'translateX(-0.2rem)', letterSpacing: '-1px'}
      ], {duration: 220, easing: 'ease-in-out'})
      doneFn()
    },
    enterSwitch(rim, done) {
      rim.animate([
        {},

        {transform: 'scale(1.05) translateX(.5rem)'}
      ], {duration: 200, easing: 'ease-in-out'})
      done()
    },
  },
  data() {
    return {
      trigger: false
    }
  },
  props: ["skill", "outer", "series"],
}
</script>


<style scoped lang="scss">
[v-cloak] {
  display: none
}

.container {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  font-family: reross-quadratic, sans-serif;
}

.switch-container {

  &_outer {
    width: 6rem;
    height: 3rem;
    background-color: whitesmoke;
    border: solid .2rem var(--color-four);
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  &_inner {
    width: 100%;
    height: 100%;
    background-color: #B8B8B8;
    border-radius: 6rem;
    box-shadow: inset 0 0 8px white;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #ABABAB;

    }
  }
}

.switch-off {
  width: 2rem;
  height: 2rem;
  background-color: gray;
  border-radius: 3rem;
  margin: 0 .3rem;


}

.switch-on {
  width: 2rem;
  height: 2rem;
  background-color: #2d8f51;
  border-radius: 3rem;
  margin: 0 .3rem;
  position: relative;
  transform: translateX(3rem);

  &:hover {
    transform: translateX(3.2rem) scale(1.05);
  }
}


.text {
  margin: 0 1rem;
  font-size: 2rem;
  font-weight: 500;
  @media screen and (min-width: 40em) {
    margin: 0 3rem;
    font-size: 3.2rem;

  }
}

</style>

<template>
  <div class="container">
    <div class="tiles">
      <div class="line_1" v-on:mouseenter="$emit('goUp')">
      </div>
      <slot></slot>
      <div class="line_2" v-on:mouseenter="$emit('goDown')"></div>
    </div>


  </div>
</template>

<script>


export default {
  name: "introductionTiles",
  components: {},
  emits:['goUp', 'goDown']
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.tiles {
  position: absolute;
  height: 100vh;
  width: 100%;

}

.line_1 {

  height: 50%;
  display: flex;
}

.line_2 {

  height: 50%;
  display: flex;
}
</style>

<template>
  <transition mode="out-in" @enter="enterTitle">
    <div v-if="elementsReady" class="title ">
      <h2>{{ title }}</h2>
    </div>
    <div v-else class="title hi-there">
      <h2>{{ title }}</h2>
    </div>
  </transition>
</template>

<script>
export default {
  name: "hiThereComp",
  props: ["elementsReady", "title"],
  setup() {
    const enterTitle = (el, doneFn) => {
      el.animate([
        {transform: 'scale(.99)', offset: .1},
        {letterSpacing: '-.3rem', offset: 0.2, easing:'ease', transform: 'scale(1.1)'},
        {letterSpacing: '.8rem'},
        {}

      ], {duration: 1200, easing: 'ease-in', fill: 'backwards', direction:"alternate"})
      doneFn()
    }

    return {
      enterTitle,

    }
  }
}
</script>

<style scoped lang="scss">
.title {

  color: var(--color-tertiary);

  h2 {
    font-size: 6rem;
    margin-bottom: 4.2rem;
    position: relative;
    left: 10%;
    max-width: 60%;
    text-align: center;
    border-radius: 16rem;
    padding: 3rem;


    @media screen and (min-width: 40em) {
      font-size: 8rem;
      padding: 1.5rem;
      bottom: 1.6rem;
    }
    @media screen and (min-width: 90em) {
      font-size: 10rem;
      bottom: -1rem;
      padding: 0;
    }
  }
}

.hi-there {
  color: var(--color-secondary);
}
</style>

<template>
  <div class="container background" :class="{ 'darker-background': background }">
    <introduction-tiles @go-up="goUp" @go-down="goDown"></introduction-tiles>

    <hi-there-comp :elements-ready="elementsReady" :title="title"></hi-there-comp>

    <div class="main-frame">

      <div class="image" :class="{ 'slide-out': slide }"></div>

      <div class="text" id="intro-text">

        <a href="https://www.instagram.com/brlnpics123/" style="color: #f2f2f2">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            />
          </svg>
        </a>
        <p>{{ text.text }}</p>
      </div>

      <div class="skills-list" v-cloak>
        <template v-for="(skill, idx) in skills" :key="idx">
          <my-skills-comp
              :skill="skill"
              :outer="elementsReady"
              :series="idx"
          ></my-skills-comp>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IntroductionTiles from "@/components/ui/introduction_tiles/introductTiles";
import sanity from "../../client";
import MySkillsComp from "@/components/intro_comp/mySkillsComp";
import HiThereComp from "@/components/intro_comp/hiThereComp";


const query = `*[_type == "introText"]{text}`;

export default {
  name: "introductionComp",
  components: {HiThereComp, MySkillsComp, IntroductionTiles},
  props: ["innerWith"],
  computed: {},
  data() {
    return {
      skills: ["Javascript/Typescript", "React", "Vue", "Node"],
      slide: false,
      background: false,
      intersectionObserver: null,
      elementsReady: false,
      profileIntersects: false,
      title: "Hi there!",
      text: "",
    };
  },
  mounted() {
    this.fetchData();
    this.intersectionObserver = new IntersectionObserver(
        this.onElementObserved,
        {
          threshold: 0.8,
          rootMargin: "100px",
        }
    );
    this.intersectionObserver.observe(document.querySelector("#intro-text"));
  },
  beforeUnmount() {
    this.intersectionObserver.unobserve(document.querySelector("#intro-text"));
    this.intersectionObserver = null;
  },
  methods: {
    async fetchData() {
      try {
        const texts = await sanity.fetch(query);
        this.text = texts[0];
      } catch (e) {
        console.log(e);
      }
    },
    goUp() {
      this.slide = true;
      this.background = false;
      setTimeout(() => {
        this.slide = false;
      }, 3000);
    },
    goDown() {
      this.background = true;
    },
    onElementObserved(entries) {
      if (entries[0].isIntersecting) {
        this.elementsReady = true;
        this.switchOfEffect();
      }
    },
    switchOfEffect() {
      setTimeout(() => {
        this.elementsReady = false;
      }, 10000);
    },
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}


.container {
  position: relative;
  transition: all 2s linear;

}

.background {
  background: top repeat url("../../assets/images/PNG/Asset 24@2x.png");
  background-size: contain;
  @media screen and (min-width: 40em) {
    background: center no-repeat url("../../assets/images/PNG/Asset 28@2x.png");
    background-size: contain;
  }
  @media screen and (min-width: 40em) {
    padding-top: 7rem;
    margin-top: 5rem;
  }

}

.darker-background {

  @media screen and (min-width: 40em) {
    background: center no-repeat url("../../assets/images/PNG/Asset 28@2x.png") whitesmoke;
    background-size: contain;
  }
}


.main-frame {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 6rem;

  @media screen and (min-width: 40em) {
    padding: 5rem 0;
    flex-direction: row;
    position: relative;
    margin-bottom: 9rem;
  }
  @media screen and (min-width: 90em) {
    padding: 10rem 0 20rem 0;
  }
}

.skills-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 40em) {

  }
  }

.image {
  background: url("../../assets/images/portraitmy.jpeg") no-repeat center;
  object-fit: cover;
  border-radius: 3rem;
  width: 300px;
  height: 300px;
  margin: 1rem auto;

  @media screen and (min-width: 40em) {
    margin: 0;
    width: 300px;
    height: auto;
    transform: translate(14rem, 4rem);
    transition: all 0.6s ease-out;
  }
  @media screen and (min-width: 90em) {
    height: 300px;
  }

  &:hover {
    transform: rotate(4deg);
  }

}

.slide-out {
  transform: rotate(4deg);
}

.text {
  position: relative;
  background: var(--color-secondary);

  padding: 2rem 2rem 2rem 2rem;
  border: dashed var(--color-six);
  border-radius: 3rem;
  color: var(--color-my-white);
  margin: 1rem;

  @media screen and (min-width: 40em) {
    margin: 0;
    width: 25%;
    z-index: 1;
    transform: translateX(-3rem);
  }

  p {
  }
}
</style>
